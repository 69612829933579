<template>
  <v-container>
    <div class="registration-confirmed-container">
      <v-row no-gutters class="text-center">
        <v-col cols="12" v-if="isSuccessStatus">
          <v-img src="/img_layout/tick.png" width="80" class="mx-auto" />
          <h1>{{ $t("register.confirmed.title") }}</h1>
          <div class="msg-body">
            {{ $t("register.confirmed.text") }}
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" class="mt-5">
        <v-btn
          v-if="!isAuthenticated"
          large
          depressed
          class="primary white--text"
          min-width="200"
          @click="handleLogin"
          >{{ $t("register.confirmed.doLogin") }}</v-btn
        >
        <v-btn
          v-else
          large
          depressed
          class="primary white--text"
          min-width="200"
          @click="goToHome"
          >{{ $t("register.confirmed.goToHome") }}</v-btn
        >
      </v-row>
    </div>

    <div class="card-container">
      <v-row no-gutters>
        <v-col cols="12" md="4">
          <v-img eager src="/img_layout/card.png" max-height="200" contain />
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="pa-0 pl-md-6 mt-4 mt-md-0 text-center text-md-left"
        >
          <h2>
            {{ $t("register.confirmed.discover.title") }}
          </h2>
          <p>
            {{ $t("register.confirmed.discover.text") }}
          </p>
          <v-row
            no-gutters
            justify="center"
            justify-sm="start"
            @click="clicked"
          >
            <v-col cols="12" md="auto">
              <router-link
                to="/page/mondo-tigros-card/"
                class="card-container--cta d-flex justify-center align-center text-decoration-none text-uppercase text-center font-weight-bold secondary"
                color="secondary primary--text"
              >
                {{ $t("register.confirmed.discover.button") }}
              </router-link>
            </v-col>

            <!--<v-btn
              depressed
              x-large
              min-width="140"
              :block="$vuetify.breakpoint.smAndDown"
              color="secondary primary--text"
              v-html="$t('register.confirmed.discover.button')"
              @click="
                window.open('https://www.tigros.it/page/mondo-tigros-card/')
              "
            /> --></v-row
          ></v-col
        >
      </v-row>
    </div>
  </v-container>
</template>
<style lang="scss">
.registration-confirmed-container {
  border: 1px solid #e4e3ef;
  margin-bottom: 40px;
  padding: 35px 0 70px;
  h1 {
    font-size: 50px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 25px;
    }
  }
  .msg-body {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 18px;
    }
  }
}
.card-container {
  border: 1px solid var(--v-gray-lighten1);
  padding: 40px;
  background-color: #fcf7d7;
  h2 {
    font-size: 30px;
    font-weight: 700;
    color: $primary;
    line-height: 35px;
  }
  p {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 300;
  }
  &--cta {
    border-radius: 4px;
    min-width: 140px;
    height: 52px;
  }
}
</style>
<script>
import clickHandler from "@/commons/mixins/clickHandler";
import categoryMixins from "~/mixins/category";
import loginMixins from "~/mixins/login";
export default {
  name: "RegisterConfirmed",

  data() {
    return {
      isSuccessStatus: false
    };
  },
  mixins: [categoryMixins, loginMixins, clickHandler],
  methods: {
    async handleLogin() {
      let isLoggedIn = await this.doLogin("OpenCart");
      if (isLoggedIn) {
        this.$router.push({
          name: "Home",
          path: "/"
        });
      }
    },
    async goToHome() {
      if (await this.needAddress()) {
        if (await this.needTimeslot()) {
          this.$router.push({
            name: "Home",
            path: "/"
          });
        }
      }
    }
  },
  created() {
    this.isSuccessStatus = this.$route.params.status === "success";
  }
};
</script>
